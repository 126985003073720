import { ComparerType, valueComparer } from "./condition-value-comparer";
import { RuleContext } from "../context-builder";
import { ExperimentCondition } from "../../models";

const typeComparer = {
  device(
    { device }: RuleContext = { device: "desktop" },
    condition: ExperimentCondition,
    compareFunction: ComparerType
  ) {
    if (!validateCompareFunctionForArray(compareFunction)) {
      return false;
    }

    return compareFunction(device, condition.value as any);
  },

  always(
    context: RuleContext,
    condition: ExperimentCondition,
    compareFunction: ComparerType
  ) {
    if (compareFunction !== valueComparer.equals) {
      return false;
    }
    return compareFunction(true, condition.value as boolean);
  },
};

/*
 * Private methods
 */

function validateCompareFunction(
  compareFunction: ComparerType,
  ...supportedFunctions: ComparerType[]
) {
  if (!supportedFunctions.some((f) => f === compareFunction)) {
    console.error(
      `unsupported compare function: '${
        compareFunction.name
      }', supported: ${supportedFunctions.map((f) => f.name)}`
    );
    return false;
  }
  return true;
}

function validateCompareFunctionForArray(compareFunction: ComparerType) {
  return validateCompareFunction(
    compareFunction,
    valueComparer.in,
    valueComparer.notIn
  );
}

export { typeComparer };
