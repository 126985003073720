import { trackerUidGenerator } from "../../utils/uidGenerator";
import { Session } from "../../models";
import { storage } from "../storage/storage";

const storageIdKey = "__ni-session-id";
const storageTimestampKey = "__ni-session-time";
const sessionPropertiesKey = "__ni-session-properties";
const expiryTime = 1000 * 60 * 30;
function createNewSession() {
  const sid = trackerUidGenerator();
  const timestamp = Date.now();
  storage.setItem(storageIdKey, sid);
  storage.setItem(storageTimestampKey, timestamp.toString());
  return { sid, timestamp };
}
export function isSessionExpired(session: Session) {
  const timeNow = Date.now();
  return (
    !session.sid ||
    !session.timestamp ||
    timeNow - session.timestamp >= expiryTime
  );
}
function didTraficSourceChange() {
  const urlQuery = window?.location?.search;
  if (!urlQuery || urlQuery === "?") {
    return false;
  }
  const oldTraficData = JSON.parse(storage.getItem(sessionPropertiesKey)) || {};

  const urlParams = new URLSearchParams(urlQuery);
  const properties = {
    utmSource: urlParams.get("utmSource"),
    gclid: urlParams.get("gclid"),
    mslkid: urlParams.get("mslkid"),
    affTrafficJoin: urlParams.get("aff_traffic_join"),
    channelClickId: urlParams.get("channel_click_id"),
  };

  const changedProperties = Object.keys(properties).filter(
    (key) => properties[key] !== oldTraficData[key]
  );

  if (changedProperties.length > 0) {
    console.log(urlQuery, oldTraficData, properties);
    storage.setItem(sessionPropertiesKey, JSON.stringify(properties));
    return true;
  }
  return false;
}

export function getSession() {
  const sid = storage.getItem(storageIdKey);
  const timestamp = storage.getItem(storageTimestampKey);
  const session: Session = { sid, timestamp: Number(timestamp) };
  if (didTraficSourceChange() || isSessionExpired(session)) {
    return createNewSession();
  }
  session.timestamp = Date.now();
  storage.setItem(storageTimestampKey, session.timestamp.toString());
  return session;
}
