import { Network, NetworkConfig } from "../models";

function getBaseUrl(network: Network) {
  if (network === "bestmoney") {
    return "https://www.bestmoney.com";
  } else if (network === "top10") {
    return "https://www.top10.com";
  } else {
    throw new Error(`base url for network ${network} is not found`);
  }
}

function getSiteId(network: Network) {
  if (network === "bestmoney") {
    return 10125;
  } else if (network === "top10") {
    return 251;
  } else {
    throw new Error(`siteId for network ${network} is not found`);
  }
}

function getCdnImageUrl(network: Network) {
  if (network === "top10") {
    return "https://images.top10.com/iu/f_auto/q_auto/v1/production/";
  } else {
    return "https://images.bestmoney.com/iu/f_auto/q_auto/v1/production/";
  }
}

function getLogoUrl(network: Network) {
  if (network === "bestmoney") {
    return "https://images.top10.com/q_auto/v1/production/sites/uploads/photo/BestMoney-logo.20201228094056.svg";
  } else if (network === "top10") {
    return "https://images.top10.com/q_auto/v1/production/sites/uploads/photo/logo.20191212140001.svg";
  } else {
    throw new Error(`logo for network ${network} is not found`);
  }
}

export function getNetworkConfig(
  network: Network = "bestmoney"
): NetworkConfig {
  return {
    baseUrl: getBaseUrl(network),
    siteId: getSiteId(network),
    logoUrl: getLogoUrl(network),
    name: network,
    imageCdnUrl: getCdnImageUrl(network),
  };
}
