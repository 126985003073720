import { Experiment } from "../models";
import { valueComparer } from "./condition-matcher/condition-value-comparer";
import { typeComparer } from "./condition-matcher/condition-type-comparer";
import { RuleContext } from "./context-builder";

const always = "always";

function isFunction(functionToCheck: any) {
  return (
    functionToCheck && {}.toString.call(functionToCheck) === "[object Function]"
  );
}

// copied from ni-rne-evaluation-engine and removed what we don't use
function isExperimentMatchConditions(rnt: Experiment, context: RuleContext) {
  let { conditions } = rnt;
  const alwaysCondition = conditions.find(
    (condition) => condition.type === always
  );

  if (alwaysCondition) {
    if (alwaysCondition.value === true) {
      // excluded segments & schedule conditions should apply even if 'always' condition is applied
      conditions = conditions.filter((condition) =>
        [always].includes(condition.type)
      );
    } else {
      conditions = rnt.conditions.filter(
        (condition) => condition.type !== always
      );
    }
  }
  for (const condition of conditions) {
    const compareFunction = valueComparer[condition.comparisonOperator];

    if (!compareFunction) {
      console.error(
        `no compare function for operator: ${condition.comparisonOperator}. ignore condition: '${condition.type}' width value: '${condition.value}'.`
      );
      return false;
    }

    const typeCompare = typeComparer[condition.type];

    if (!isFunction(typeCompare)) {
      console.error(`type '${condition.type}' does not implemented`);
      return false;
    }

    if (!typeCompare(context, condition, compareFunction)) {
      return false;
    }
  }
  return true;
}

function getMatchedExperiment(experiments: Experiment[], context: RuleContext) {
  return experiments?.find((experiment) =>
    isExperimentMatchConditions(experiment, context)
  );
}

export { isExperimentMatchConditions, getMatchedExperiment };
