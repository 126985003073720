import { Logger, Status } from "../logger/logger";
import { WidgetDataAPIResponse } from "../models";

type Validator = (data: WidgetDataAPIResponse) => boolean;

const dataValidatorsMap: Record<Status, Array<[string, Validator]>> = {
  error: [
    [
      "missing baseWidgetId",
      (data: WidgetDataAPIResponse) =>
        !data?.widget?.baseWidgetId &&
        data?.widget?.type !== "publisher-widget-hub",
    ],
  ],
  warn: [
    [
      "missing siteId",
      (data: WidgetDataAPIResponse) =>
        !data?.widget?.publisherContext?.publisherBrand?.xsiteSite,
    ],
    [
      "missing publisherSlug",
      (data: WidgetDataAPIResponse) =>
        !data?.widget?.publisherContext?.publisher?.publisherSlug,
    ],
    [
      "missing advertiserDisclosure",
      (data: WidgetDataAPIResponse) =>
        !data?.widget?.advertiserDisclosure?.text ||
        !data?.widget?.advertiserDisclosure?.title,
    ],
  ],
  debug: [],
  info: [],
};

export function verifyResponse(data: WidgetDataAPIResponse) {
  return Object.entries(dataValidatorsMap)
    .flatMap(([logLevel, validatorsArray]) => {
      return validatorsArray.map(([message, isInvalid]) => {
        if (isInvalid(data)) {
          return {
            logLevel: logLevel as Status,
            message,
          };
        } else {
          return null;
        }
      });
    })
    .filter((item) => item);
}

export function verifyResponseAndLog(
  data: WidgetDataAPIResponse,
  logger: Logger,
  context: any
) {
  const responseErrors = verifyResponse(data);

  const errorContext = {
    widgetType: data?.widget?.type,
    publisherSlug: data?.widget?.publisherContext?.publisher?.publisherSlug,
  };
  responseErrors.forEach((error) =>
    logger.log(error.message, { ...context, ...errorContext }, error.logLevel)
  );
}
