import { extractNetworkId } from "./utils";

declare global {
  interface Window {
    ni_platform: string;
    ni_server_side_impression_id: string;
    ni_server_side_user_id: string;
  }
}

export const isXsite = !!(
  window.ni_platform === "xsite" &&
  window.ni_server_side_impression_id &&
  window.ni_server_side_user_id
);

const publisherId = window.document.currentScript.dataset.niPublisherId;
export const pubParam1 = window.document.currentScript.dataset.pubParam1;
export const pubParam2 = window.document.currentScript.dataset.pubParam2;
const globalNetwork = extractNetworkId(
  window.document.currentScript as HTMLElement
);
const nested = window.document.currentScript.dataset.nested !== undefined;
const contextKey = window.document.currentScript.dataset.categoryTag;

let wuid = window.document.currentScript.dataset.wuid;
let wiid = window.document.currentScript.dataset.wiid;
let redirectUrl = window.document.currentScript.dataset.redirectUrl;

const isMonitoring = window.document.currentScript.dataset.niMonitoring === "1";
const monitoringSource =
  window.document.currentScript.dataset.niMonitoringSource || "";

const slotEnabled = window.document.currentScript.dataset.slotsDisabled !== "1";

const isDebugModeEnabled =
  window.document.currentScript.dataset.debugMode === "1";

if (isXsite) {
  wuid = window.ni_server_side_user_id;
  wiid = window.ni_server_side_impression_id;
  redirectUrl = location.protocol + "//" + location.host;
}

export const urlQuery = window.location.search;

const loadImmediately = window.document.currentScript.dataset.lazyLoad !== "1";

export {
  wiid,
  wuid,
  redirectUrl,
  publisherId,
  globalNetwork,
  nested,
  isMonitoring,
  monitoringSource,
  slotEnabled,
  isDebugModeEnabled,
  loadImmediately,
  contextKey,
};
