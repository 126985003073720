type Context = Record<string, any>;
type CommonContext = {
  widgetType: string;
  niInstanceId?: string;
  publisherSlug?: string;
};
export enum Status {
  info = "info",
  warn = "warn",
  error = "error",
  debug = "debug",
}

export interface Transport {
  info(message: string, context: Context): any;
  warn(message: string, context: Context): any;
  error(message: string, context: Context): any;
  debug(message: string, context: Context): any;
  log(message: string, context: Context, status: Status): any;
}

interface LoggerConfig {
  transports: Transport | Transport[];
  context: CommonContext;
}

class Logger {
  private transports: Transport[];
  private context: CommonContext;

  constructor(config: LoggerConfig) {
    this.configure(config);
  }
  /**
   * update logger configuration
   */
  public configure({ context, transports }: LoggerConfig) {
    this.transports = Array.isArray(transports) ? transports : [transports];
    this.context = context;
  }

  public info(message: string, context: Context) {
    return this.log(message, context, Status.info);
  }
  public warn(message: string, context: Context) {
    return this.log(message, context, Status.warn);
  }
  public error(message: string, context: Context) {
    return this.log(message, context, Status.error);
  }
  public debug(message: string, context: Context) {
    return this.log(message, context, Status.debug);
  }

  public log(message: string, context: Context, status: Status) {
    const mergedContext = {
      common: this.context,
      ...context,
    };
    this.transports.forEach((transport) => {
      if (status in transport) {
        transport[status](message, mergedContext);
      } else {
        transport.log(message, mergedContext, status);
      }
    });
  }
}

const logger = new Logger({ context: {} as any, transports: [] });

export { Logger, logger };
