export const trackerUidGenerator = () => {
  const totalSize = 20;
  const timestampSize = 3;
  const hostnameSize = 3;
  const timestamp: number = +new Date();
  const hostname = document?.location?.hostname || "";
  const timestampPart = getLastTimestampDigits(timestampSize, timestamp);
  const hostnamePart = getLastHostNameDigits(hostnameSize, hostname);
  const randomStringSize =
    totalSize - timestampPart.length - hostnamePart.length;
  const randomStringPart = getRandomString(randomStringSize);
  return `${randomStringPart}${timestampPart}${hostnamePart}`;
};

function getLastTimestampDigits(numOfDigits: number, timestamp: number) {
  const timeStampString = `${timestamp}`;
  return timeStampString.slice(-numOfDigits);
}

function getLastHostNameDigits(numOfDigits: number, hostname: string) {
  return hostname.slice(-numOfDigits);
}

function getRandomString(size: number) {
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let uid = "";
  for (let i = 0; i < size; i++) {
    uid += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  return uid;
}

export const isValidTrackerUid = function (uid: string) {
  return !!uid.match(/^[a-zA-Z0-9]{20}$/);
};

export const isValidUrl = function (url: string) {
  return (
    typeof url === "string" &&
    !!url.match(
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
    )
  );
};
