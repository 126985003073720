import { Storage } from "./types";

const testKey = "__ni-w-test-ls";
const testVal = "__ni-w-test-ls-val";

function isLocalStorageSupported() {
  try {
    window.localStorage.setItem(testKey, testVal);
    const val = window.localStorage.getItem(testKey);
    window.localStorage.removeItem(testKey);
    return val === testVal;
  } catch (err) {
    return false;
  }
}

const localStorage: Storage = window.localStorage;

export { isLocalStorageSupported, localStorage };
