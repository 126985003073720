interface ErrorResponse {
  errors: { message: string; error: { message: string } };
  error: { message: string };
}
interface ErrorResponse {
  error: { message: string };
}

interface ErrorContext {
  status: number;
  response: ErrorResponse;
  [k: string]: any;
}

const getErrorMessage = (res: ErrorResponse) =>
  res?.errors?.message || res?.errors?.error?.message || res?.error?.message;

class ApiError extends Error {
  public context: { status: number; message: string; [k: string]: any };
  constructor(message: string, context: ErrorContext) {
    super(message);

    const { response, status, ...rest } = context || {};

    this.context = {
      status,
      message: getErrorMessage(response),
      ...rest,
    };
  }
}

export { ApiError };
