import { IFrameOptions } from "iframe-resizer";
import iframeResizer from "iframe-resizer/js/iframeResizer";

const iframeResizerOptions: IFrameOptions = {
  checkOrigin: false,
  heightCalculationMethod: "taggedElement",
  widthCalculationMethod: "taggedElement",
};

function initIframeResizer(iframe: HTMLIFrameElement) {
  iframeResizer(iframeResizerOptions, iframe);
}

export { initIframeResizer };
