import md5 from "crypto-js/md5";

const checkForHexRegExp = new RegExp('^[0-9a-fA-F]{24}$');

const generateToken = () => {
  const isoD = new Date().toISOString().split("T")[0];
  const ua = navigator.userAgent;
  const token = md5(ua.slice(0, 10) + isoD);

  return token.toString();
};

// took the same regexp as we have in xbox PublisherContextForm
const sanitizeUrl = (url = "") =>
  url.replace(/(^\w+:|^)\/\/|\/$/g, "").toLowerCase();

const generateCacheKey = (prefix: string, data: Record<string, string>) => {
  return md5(prefix + "_" + JSON.stringify(data)).toString();
};

const isObjectId = (id: string) => {
  return typeof id === 'string' && id.length === 24 && checkForHexRegExp.test(id);
};

export { generateToken, sanitizeUrl, generateCacheKey, isObjectId };
