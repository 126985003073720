import { getNetworkConfig } from "../utils/getNetworkConfig";
import { logger } from "../logger/logger";
import {
  globalNetwork,
  isMonitoring,
  monitoringSource,
  nested,
  publisherId,
  slotEnabled,
  loadImmediately,
} from "./currentScriptData";
import {
  isProcessed,
  markProcessed,
  onNewElementInjected,
  setSlotsLoadingStatus,
} from "./utils";
import "./loader.scss";
import { createLoaderTransports } from "../logger/loaderTransports";

import { getVerticalIdForLink, initLink } from "./links";
import {
  addExternalEventListener,
  getTracker,
  getTrackerConfig,
  impressionEventTrigger,
  initLoaderTracking,
} from "./tracking";
import { initWidget } from "./widget";
import { onDomReady } from "./domUtils";
import { handleSlots, TransformedSlots } from "./handleSlots";
import { resolveSlots } from "./slots";

const PRELOAD_OFFSET_PX = 300;

logger.configure({
  context: {
    widgetType: "loader",
  },
  transports: createLoaderTransports(),
});

let slots: TransformedSlots;

const trackerConfig = getTrackerConfig();
const uid = trackerConfig.context.visitor.uid;
const iid = trackerConfig.context.impression.iid;

function init() {
  if (slots) {
    handleSlots(slots);
  }
  const widgetContainers = window.document.querySelectorAll<HTMLElement>(
    "[data-ni-instance-id]"
  );
  const niLinks =
    window.document.querySelectorAll<HTMLLinkElement>("[data-ni-link]");

  if (!publisherId) {
    logger.error("data-ni-publisher-id is not provided", {});
    return;
  }

  widgetContainers.forEach((container, idx) => {
    if (!container.dataset.widgetKey) {
      container.dataset.widgetKey = `${container.dataset.niInstanceId}_${idx}`;
    }
    if (isProcessed(container)) {
      return;
    }
    markProcessed(container);

    if (loadImmediately || !window.IntersectionObserver) {
      initWidget({ container });
    } else {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            observer.disconnect();
            initWidget({ container });
          }
        },
        {
          rootMargin: `${PRELOAD_OFFSET_PX}px 0px ${PRELOAD_OFFSET_PX}px 0px`,
        }
      );

      observer.observe(container);
    }
  });

  if (niLinks.length !== 0) {
    const networkConfig = getNetworkConfig(globalNetwork);

    // means that there are only links on the page, and we need to trigger pageView with the data that we have
    if (!impressionEventTrigger.isTriggered && widgetContainers.length === 0) {
      const verticalId = getVerticalIdForLink(niLinks[0]);
      const tracker = getTracker({
        verticalId,
        source: publisherId,
        siteId: networkConfig.siteId,
      });
      impressionEventTrigger.trigger(tracker, window, {
        monitoringSource,
        isMonitoring,
      });
    }

    niLinks.forEach((anchor) => {
      if (isProcessed(anchor)) {
        return;
      }
      markProcessed(anchor);
      initLink(anchor, uid, iid);
    });
  }
}

onDomReady(async () => {
  init();

  if (slotEnabled) {
    setSlotsLoadingStatus(true);
    slots = await resolveSlots();
    setSlotsLoadingStatus(false);

    init();
  }

  document.addEventListener(
    "animationstart",
    onNewElementInjected.bind(null, init),
    false
  );

  initLoaderTracking(nested);
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export { init, addExternalEventListener as addEventListener };
