import { ApiError } from "./ApiError";
import {
  generateCacheKey,
  generateToken,
  sanitizeUrl,
  isObjectId,
} from "./utils";
import { SlotResponse } from "../loader/handleSlots";

const OPEN_API_PATH = __OPEN_API_PATH__;
const cachePrefix = "slots";

export interface GetSlotsResult {
  data: SlotResponse;
  fetchingMetadata: {
    loadTime: number;
    cacheHit: boolean;
  };
}

export const getSlots = async (
  { publisherId, contextKey }: { publisherId: string; contextKey?: string },
  iid: string,
  uid: string
): Promise<GetSlotsResult> => {
  const startedLoadingAt = Date.now();

  const urlParams = window?.location?.search;
  const urlOverrideParam = new URLSearchParams(urlParams).get(
    "ni-override-url"
  );

  const url = sanitizeUrl(
    urlOverrideParam || `${window.location.host}${window.location.pathname}`
  );
  const query = {
    url,
  };

  if (!isObjectId(publisherId)) {
    throw new Error(
      `Slots integration is not correct: received publisher = ${publisherId}`
    );
  }

  if (contextKey) {
    query["contextKey"] = contextKey;
  }

  const apiUrl = new URL(
    `${OPEN_API_PATH}/publishers/publisher-widget-slot/${publisherId}`
  );
  apiUrl.search = new URLSearchParams(query).toString();

  const res = await fetch(apiUrl, {
    headers: {
      "x-ni-uid": uid,
      "x-ni-iid": iid,
      Authorization: `Bearer ${generateToken()}`,
      "ni-cache-key": generateCacheKey(cachePrefix, {
        publisherId,
        url,
      }),
    },
    credentials: "omit",
  });

  const jsonResponse = await res.json();

  if (!res.ok) {
    throw new ApiError("Slots API Request Failure", {
      status: res.status,
      response: jsonResponse,
      apiUrl: apiUrl.toString(),
    });
  }

  return {
    data: jsonResponse?.content?.content?.data[0],
    fetchingMetadata: {
      loadTime: Date.now() - startedLoadingAt,
      cacheHit: res.headers.get("x-cache") === "Hit from cloudfront",
    },
  };
};
