import { resolveBucketData } from "./resolveBucketData";

import { initIframeResizer } from "../iframe-resizer/initIframeResizer";
import { logger } from "../logger/logger";
import { Network, WidgetDataAPIResponse } from "../models";
import { Preset, Theme } from "../styles/fonts";
import { getNetworkConfig } from "../utils/getNetworkConfig";
import {
  globalNetwork,
  isMonitoring,
  publisherId,
  redirectUrl,
  monitoringSource,
  urlQuery,
} from "./currentScriptData";
import {
  applyStylesToWrapper,
  createIframe,
  insertFonts,
  insertFontsV2,
  insertIframe,
  insertScript,
} from "./domUtils";
import {
  getTracker,
  getTrackerConfig,
  impressionEventTrigger,
} from "./tracking";
import {
  extractNetworkId,
  parseInstanceId,
  WidgetInitStatus,
  extractThemeV2,
} from "./utils";
import { verifyResponseAndLog } from "./verifyResponse";

function getWidgetConfig(wrappingElement: HTMLElement) {
  const instanceId = wrappingElement.dataset.niInstanceId;
  const formTitle = wrappingElement.dataset.formTitle;
  const loadingStatus = wrappingElement.dataset
    .loadingStatus as WidgetInitStatus;
  const theme = wrappingElement.dataset.theme as Theme;
  const showPoweredBy =
    wrappingElement.dataset.showPoweredBy &&
    wrappingElement.dataset.showPoweredBy !== "false";
  const showSponsored =
    wrappingElement.dataset.showSponsored &&
    wrappingElement.dataset.showSponsored !== "false";
  const showAdvertiserDisclosure =
    wrappingElement.dataset.ad && wrappingElement.dataset.ad !== "false";
  const numOfProducts = wrappingElement.dataset.numOfProducts
    ? parseInt(wrappingElement.dataset.numOfProducts, 10)
    : null;
  const displayScore =
    wrappingElement.dataset.displayScore &&
    wrappingElement.dataset.displayScore !== "false";
  const widgetKey = wrappingElement.dataset.widgetKey;
  const nestedWidget = !!wrappingElement.dataset.nested;
  const slotIndex = wrappingElement.dataset.niSlotIndex;
  const slotRole = wrappingElement.dataset.niSlot;
  const slotId = wrappingElement.dataset.niSlotId;
  const network = extractNetworkId(wrappingElement);
  const themeV2 = extractThemeV2(wrappingElement);
  const preset = wrappingElement.dataset.preset as Preset;

  /* deprecated */
  const borderRadius = wrappingElement.dataset.borderRadius;

  const dataPath = wrappingElement.dataset.dataPath as string;

  return {
    instanceId,
    text: { formTitle },
    loadingStatus,
    numOfProducts,
    theme,
    displayScore,
    showPoweredBy,
    showSponsored,
    showAdvertiserDisclosure,
    widgetKey,
    nested: nestedWidget,
    borderRadius,
    network,
    dataPath,
    slotData: {
      slotIndex,
      slotRole,
      slotId,
    },
    themeV2,
    preset,
  };
}

async function initWidget({
  container: wrappingElement,
}: {
  container: HTMLElement;
}) {
  const startedLoadingAt = Date.now();
  const widgetConfig = getWidgetConfig(wrappingElement);
  const dataKey = "widget_data";
  const trackerConfig = getTrackerConfig();

  if (
    widgetConfig.loadingStatus &&
    !wrappingElement?.hasAttribute("data-ni-slot")
  ) {
    console.log(
      `widget for instanceId: ${widgetConfig.instanceId} is already loaded.`
    );
    return;
  }

  if (!widgetConfig.instanceId) {
    logger.error(
      `ni-instance-id attribute is missing on widget wrapping element`,
      {
        publisherId,
      }
    );

    return;
  }

  wrappingElement.dataset.loadingStatus = WidgetInitStatus.loading;

  const { appInstanceId, widgetId } = parseInstanceId(widgetConfig.instanceId);

  let path: string;
  let headers: Headers;
  let data: WidgetDataAPIResponse;
  let trackerData: { verticalId: string; siteId: number; source: string };

  const bucketDataResolvingPayload = {
    appInstanceId: widgetConfig.instanceId,
    publisherId,
    wuid: trackerConfig.context.visitor.uid,
    wiid: trackerConfig.context.impression.iid,
    apiBaseUrl: widgetConfig.dataPath,
    redirectUrl,
    isMonitoring,
    monitoringSource,
  };

  try {
    ({ path, data, trackerData, headers } = await resolveBucketData(
      bucketDataResolvingPayload
    ));
    verifyResponseAndLog(data, logger, {
      niInstanceId: widgetConfig.instanceId,
    });
  } catch (err) {
    const msg =
      err.message ||
      `something went wrong when resolving data for instanceId: ${widgetConfig.instanceId}`;
    logger.error(`Data Fetching Error: ${msg}`, {
      niInstanceId: widgetConfig.instanceId,
      originalException: err,
      bucketDataResolvingPayload,
      ...err?.context,
    });

    // remove loader
    wrappingElement.dataset.loadingStatus = WidgetInitStatus.failed;
    return;
  }

  const iframe = createIframe(wrappingElement);
  const tracker = getTracker(trackerData);

  // TODO: i guess we don't need it anymore, need to check with Oren
  applyStylesToWrapper(
    wrappingElement,
    !widgetConfig.nested && (data?.widget as any)?.container_styles
  );
  await insertIframe(wrappingElement, iframe);
  initIframeResizer(iframe);

  // currently network is not supported in xbox
  // need to check and update the types properly
  const network: Network =
    (data as any)?.network || widgetConfig.network || globalNetwork;
  const networkConfig = getNetworkConfig(network);
  const theme = widgetConfig.theme || data.widget?.theme;
  // @TODO: do the merge of themes
  const themeV2 = Object.assign(
    {},
    data.widget?.publisherContext?.publisherBrand?.theme,
    data.widget?.themeV2,
    widgetConfig.themeV2
  );
  const preset =
    widgetConfig.preset ||
    data.widget?.publisherContext?.publisherBrand?.preset ||
    "default";

  if (!impressionEventTrigger.isTriggered) {
    impressionEventTrigger.trigger(tracker, window, {
      monitoringSource,
      isMonitoring,
    });
  }

  // to make it backwards compatible used the old format
  iframe.contentWindow[dataKey] = {
    q: [
      [
        "init",
        {
          ...widgetConfig,
          tracker: {
            ...trackerConfig,
            siteId: trackerData.siteId,
            verticalId: trackerData.verticalId,
            publisherId: trackerData.source,
          },
          network: networkConfig,
          widgetId,
          publisherId,
          appInstanceId,
          widgetData: data,
          theme,
          redirectUrl,
          themeV2,
          preset,
          urlQuery,
        },
      ],
    ],
  };
  // @DEPRECATED: this is old theme forts injection, we will remove it after all the widgets will be migrated
  // to the new mechanism of theming
  insertFonts(theme, iframe.contentWindow);

  // new fonts insertion
  insertFontsV2(themeV2, preset, iframe.contentWindow);

  insertScript(path, dataKey, iframe.contentWindow, () => {
    wrappingElement.dataset.loadingStatus = WidgetInitStatus.loaded;
    const widgetLoadTime = Date.now() - startedLoadingAt;

    insertScript(
      tracker.getWidgetLoadEventUrl({
        widgetId: data.widget.baseWidgetId,
        widgetLoadTime,
        slotsLoadTime: trackerConfig?.slotsMetadata?.loadTime,
        widgetName: data.widget.displayName,
        widgetType: data.widget.type,
        widgetAppPath: data.widget.appPath,
        widgetCacheHit:
          headers.get("x_cache_serving") === "Hit from cloudfront",
        slotsCacheHit: trackerConfig?.slotsMetadata?.cacheHit,
      }),
      undefined,
      window
    );
  });
}

export { initWidget };
