import { hgsFontCss } from "./hgsFontCss";

export type FontConfig = Partial<HTMLLinkElement> & { css?: string };
export type Theme = typeof themes[number];
export type Preset = typeof presets[number];
export type Fonts = keyof typeof fontsMapV2;

export const themes = [
  "dm",
  "light",
  "askmoney",
  "ask",
  "frontstory",
  "blue",
  "homebuyer",
  "top10",
  "benzinga",
  "moneygeek",
  "marketwatch",
  "ratezip",
  "moneyselect",
  "fha",
] as const;

export const presets = ["default"] as const;

const common: FontConfig[] = [
  {
    rel: "preconnect",
    href: "https://fonts.googleapis.com",
  },
  {
    crossOrigin: "",
    rel: "preconnect",
    href: "https://fonts.gstatic.com",
  },
];

export const fontsMap: Record<Theme, FontConfig[]> = {
  dm: [
    ...common,
    {
      href: "https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;800;900&family=Roboto:wght@400;900&display=swap",
      rel: "stylesheet",
    },
  ],
  light: [
    ...common,
    {
      href: "https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Roboto:wght@400;900&display=swap",
      rel: "stylesheet",
    },
  ],
  askmoney: [
    ...common,
    {
      href: "https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;900&family=Oswald:wght@700&display=swap",
      rel: "stylesheet",
    },
  ],
  frontstory: [
    ...common,
    {
      href: "https://fonts.googleapis.com/css2?family=Poppins:wght@400;700;800;900&family=Roboto:wght@400;900&display=swap",
      rel: "stylesheet",
    },
  ],
  ask: [
    ...common,
    {
      href: "https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Roboto:wght@400;900&display=swap",
      rel: "stylesheet",
    },
  ],
  blue: [
    ...common,
    {
      href: "https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap",
      rel: "stylesheet",
    },
  ],
  homebuyer: [
    {
      rel: "preconnect",
      href: "https://app.homebuyer.com",
    },
    {
      crossOrigin: "",
      rel: "preconnect",
      href: "https://app.homebuyer.com",
    },
    {
      href: "https://widgets.bestmoney.com/assets/fonts/tiempos/tiempos-headline.css",
      rel: "stylesheet",
    },
  ],
  top10: [
    {
      css: hgsFontCss,
    },
  ],
  benzinga: [
    ...common,
    {
      href: "https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;900&display=swap",
      rel: "stylesheet",
    },
    {
      href: "https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap",
      rel: "stylesheet",
    },
  ],
  moneygeek: [
    ...common,
    {
      href: "https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;600;700&display=swap",
      rel: "stylesheet",
    },
    {
      href: "https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@400;500;600;700&display=swap",
      rel: "stylesheet",
    },
  ],
  marketwatch: [
    ...common,
    {
      href: "https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap",
      rel: "stylesheet",
    },
    {
      href: "https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap",
      rel: "stylesheet",
    },
  ],
  ratezip: [
    ...common,
    {
      href: "https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap",
      rel: "stylesheet",
    },
  ],
  moneyselect: [
    ...common,
    {
      href: "https://fonts.googleapis.com/css2?family=Yantramanav:wght@400;500;600;700&display=swap",
      rel: "stylesheet",
    },
  ],
  fha: [
    ...common,
    {
      href: "https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap",
      rel: "stylesheet",
    },
  ],
};

export const fontsMapV2 = {
  roboto: {
    fontFamily: "Roboto",
    fontLinks: [
      ...common,
      {
        href: "https://fonts.googleapis.com/css2?family=Roboto:wght@400;900&display=swap",
        rel: "stylesheet",
      },
    ],
  },
  poppins: {
    fontFamily: "Poppins",
    fontLinks: [
      ...common,
      {
        href: "https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;800;900&display=swap",
        rel: "stylesheet",
      },
    ],
  },
  montserrat: {
    fontFamily: "Montserrat",
    fontLinks: [
      ...common,
      {
        href: "https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap",
        rel: "stylesheet",
      },
    ],
  },
  open_sans: {
    fontFamily: "Open Sans",
    fontLinks: [
      ...common,
      {
        href: "https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;900&display=swap",
        rel: "stylesheet",
      },
    ],
  },

  inter: {
    fontFamily: "Inter",
    fontLinks: [
      ...common,
      {
        href: "https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap",
        rel: "stylesheet",
      },
    ],
  },
  tiempos_headline: {
    fontFamily: "Tiempos Headline",
    fontLinks: [
      {
        href: "https://widgets.bestmoney.com/assets/fonts/tiempos/tiempos-headline.css",
        rel: "stylesheet",
      },
    ],
  },
  hurme_geometric_sans: {
    fontFamily: "'hurmegeometricsans_no3_6', sans-serif",
    fontLinks: [
      {
        css: hgsFontCss,
      },
    ],
  },
  cabin: {
    fontFamily: "Cabin",
    fontLinks: [
      ...common,
      {
        href: "https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;600;700&display=swap",
        rel: "stylesheet",
      },
    ],
  },
  source_sans_3: {
    fontFamily: "'source sans 3', sans-serif",
    fontLinks: [
      ...common,
      {
        href: "https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@400;500;600;700&display=swap",
        rel: "stylesheet",
      },
    ],
  },
  lato: {
    fontFamily: "Lato, sans-serif",
    fontLinks: [
      {
        href: "https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap",
        rel: "stylesheet",
      },
    ],
  },
  yantramanav: {
    fontFamily: "Yantramanav, sans-serif",
    fontLinks: [
      {
        href: "https://fonts.googleapis.com/css2?family=Yantramanav:wght@400;500;700&display=swap",
        rel: "stylesheet",
      },
    ],
  },
  arial: {
    fontFamily: "Arial, sans-serif",
    fontLinks: [],
  },
  mulish: {
    fontFamily: "Mulish, sans-serif",
    fontLinks: [
      {
        href: "https://fonts.googleapis.com/css2?family=Mulish:wght@400;600;700;800&display=swap",
        rel: "stylesheet",
      },
    ],
  },
} as const;

export const presetFonts: Record<Preset, Fonts> = {
  default: "inter",
};
