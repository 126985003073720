import {
  Network,
  WidgetDataAPIResponse,
  ThemeV2,
  Experiment,
  ExperimentVariation,
} from "../models";

export enum WidgetInitStatus {
  loading = "loading",
  loaded = "loaded",
  failed = "failed",
}

export function markProcessed(element: HTMLElement) {
  element.dataset.processed = "true";
}

export function isProcessed(element: HTMLElement) {
  return element.dataset.processed === "true";
}

export function onNewElementInjected(callback: () => void, e: AnimationEvent) {
  const target = e.target as HTMLElement;
  const isNiElement = (element: HTMLElement) =>
    Object.prototype.hasOwnProperty.call(element.dataset, "niInstanceId") ||
    Object.prototype.hasOwnProperty.call(element.dataset, "niLink");

  if (target && isNiElement(target) && !isProcessed(target)) {
    callback();
  }
}

export function parseInstanceId(id: string) {
  const [widgetId, appInstanceId] = id.split("_");

  return {
    widgetId,
    appInstanceId,
  };
}

export function extractNetworkId(element: HTMLElement) {
  return element.dataset.niNetwork as Network;
}

export function extractTrackerData(data: WidgetDataAPIResponse) {
  const verticalId =
    data.widget.context?.verticalId || getLegacyVerticalId(data);
  const siteId =
    data.widget.publisherContext?.publisherBrand?.xsiteSite?.siteId;
  const source = data.widget.publisherContext?.publisher?.publisherSlug;

  if (!siteId || !source || !verticalId) {
    console.warn(
      "some of the tracking params (siteId,source,verticalId) are not available"
    );
  }

  return {
    verticalId,
    siteId,
    source,
  };
}

// TODO: Change all JSONs to use widget's context and remove this
function getLegacyVerticalId(data: WidgetDataAPIResponse): string {
  const widgetKeys = [
    "publisherCharts",
    "publisherInlineCharts",
    "publisherWidgetHubs",
    "publisherQuizs",
    "publisherSidebarMiniCharts",
    "publisherSearchSliders",
  ] as const;

  return widgetKeys.reduce((acc, key) => {
    if (!acc) {
      return data.widget[key]?.[0]?.basicInfo?.context?.verticalId;
    }
    return acc;
  }, "");
}

export function setSlotsLoadingStatus(shouldSet: boolean) {
  const selector = `[data-ni-slot]${
    shouldSet ? "" : ":not([data-ni-instance-id])"
  }`;
  const slotElements = document.querySelectorAll(
    selector
  ) as NodeListOf<HTMLElement>;

  slotElements.forEach((slot) => {
    shouldSet
      ? (slot.dataset.loadingStatus = WidgetInitStatus.loading)
      : delete slot.dataset.loadingStatus;
  });
}

export function extractThemeV2(element: HTMLElement) {
  try {
    const themeV2 = element.dataset.themeV2;
    return themeV2 ? (JSON.parse(themeV2) as ThemeV2) : null;
  } catch (err) {
    return null;
  }
}

export function getSlotExperimentEventData(
  {
    experiment,
    variant,
  }: {
    experiment: Experiment;
    variant: ExperimentVariation;
  },
  { iid, uid }: { iid: string; uid: string }
) {
  if (experiment && variant) {
    return {
      experiment,
      variant,
      uid,
      iid,
    };
  }

  return null;
}
