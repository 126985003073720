import { logger } from "../logger/logger";
import { pubParam1, pubParam2 } from "./currentScriptData";

export function getVerticalIdForLink(link: HTMLLinkElement) {
  if (!link) {
    return null;
  }

  const params = new URLSearchParams(link.href);
  const verticalId = params.get("vertid");

  if (verticalId === undefined) {
    logger.error(`can't get vertical id for links: ${link} `, {});
  }

  return verticalId;
}

export function initLink(
  linkElement: HTMLLinkElement,
  uid: string,
  iid: string
) {
  if (linkElement.href.indexOf("&wiid=") > 0) {
    return;
  }

  const firstDelimiter = linkElement.href.indexOf("?") > 0 ? "&" : "?";
  const publisherParams =
    pubParam1 && pubParam2 && linkElement.href.indexOf("pub_param") === -1
      ? `&pub_param_1=${pubParam1}&pub_param_2=${pubParam2}`
      : "";
  linkElement.href = `${linkElement.href}${firstDelimiter}wuid=${uid}&wiid=${iid}${publisherParams}`;
}
