import { customAlphabet } from "nanoid";
import md5 from "crypto-js/md5";
import {
  WidgetApi,
  Context,
  TrackerConfig,
  ProductList,
  SlotData,
  Experiment,
  ExperimentVariation,
  Session,
  AppConfigurations,
} from "../../models";
import { PwiEvent, UIEvent, WidgetLoadEvent } from "../messenger/common";
import { User } from "./user";
import { getSession, isSessionExpired } from "./session";
import debounce from "lodash.debounce";

const nanoid = customAlphabet(
  "1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
  10
);

const clearEmptyKeys = <Obj extends object>(obj: Obj): Partial<Obj> => {
  return Object.keys(obj).reduce(
    (acc, key) => {
      if (Object.prototype.hasOwnProperty.call(acc, key) && !obj[key]) {
        delete acc[key];
      }

      return acc;
    },
    { ...obj }
  );
};

export interface TrackerOptions extends TrackerConfig {
  isXsite: boolean;
  trackerUrl: string;
}

export interface GetPageViewUrlOptions {
  isMonitoring?: boolean;
  monitoringSource?: string;
}

declare global {
  interface Window {
    niDebugModeEnabled: boolean;
  }
}

export class Tracker implements WidgetApi {
  public readonly user: User;
  private readonly pageURL: string;
  private readonly screenW: number;
  private readonly screenH: number;
  private readonly userContext: Context;
  private readonly trackerUrl: string;
  private readonly verticalId: string;
  private readonly siteId: number;
  private readonly publisherId: string;
  // window.location.href
  private readonly isXsite: boolean;
  private readonly debugModeEnabled: boolean;
  private session: Session;

  constructor(options: TrackerOptions) {
    this.userContext = options.context;
    this.pageURL = options.pageUrl || window.location.href;
    this.screenW = window.screen.availWidth;
    this.screenH = window.screen.availHeight;
    this.trackerUrl = options.trackerUrl;

    this.verticalId = options.verticalId;
    this.siteId = options.siteId;
    this.publisherId = options.publisherId;
    this.isXsite = options.isXsite;
    this.user = new User(this.userContext.visitor?.uid);
    if (!this.userContext.impression?.iid) {
      this.userContext.impression.iid = this.generateImpressionId();
    }
    this.session = getSession();
    if (!this.userContext.visitor?.uid) {
      this.userContext.visitor.uid = this.user.getUserId();
    }
    this.debugModeEnabled = !!options.isDebugModeEnabled;
    this.bindUserInteraction();
  }

  public getImpressionId = () => this.userContext.impression.iid;
  public getSessionId = () => {
    if (isSessionExpired(this.session)) {
      this.userContext.impression.iid = this.generateImpressionId();
    }
    this.session = getSession();
    return this.session.sid;
  };

  public extendSession = () => {
    if (!isSessionExpired(this.session)) {
      this.session = getSession();
    }
  };
  public getPageUrl = () => this.pageURL;
  public getUserContext = () => this.userContext;
  public getClientImpressionUrl = (WidgetId?: string) => {
    const widgetParam = WidgetId
      ? `${this.pageURL.indexOf("?") > 0 ? "&" : "?"}wt=${WidgetId}`
      : ``;
    const clientImpressionEvent = {
      events: [
        {
          event: "client_impression",
          attributes: {
            iid: this.getImpressionId(),
            gvk: this.user.getUserId(),
            scr_w: this.screenW,
            scr_h: this.screenH,
            eid: nanoid(20),
            uid: this.user.getUserId(),
            cms_platform: "xsite",
            url: `${this.pageURL}${widgetParam}`,
            ts: Date.now(),
          },
        },
      ],
    };

    const iid = this.getImpressionId();
    const uid = this.user.getUserId();
    const contextHeader = {
      uid,
      iid,
      ni_session_id: this.getSessionId(),
    };
    if (this.debugModeEnabled) {
      console.log({
        clientImpressionEvent: { data: clientImpressionEvent, contextHeader },
      });
    }
    return this.sign(
      `${this.trackerUrl}/?data=${this.toBase64(
        clientImpressionEvent
      )}&ctx=${this.toBase64(contextHeader)}`
    );
  };
  public getPageViewUrl = (
    WidgetId?: string,
    options: GetPageViewUrlOptions = {}
  ) => {
    // console.log('this.impressionId =', this.impressionId);
    const widgetParam = WidgetId
      ? `${this.pageURL.indexOf("?") > 0 ? "&" : "?"}wt=${WidgetId}`
      : ``;

    const urlMonitoringQueryStringParameters = options.isMonitoring
      ? `&monitoring=1&monitoring_source=${
          options.monitoringSource || "unknown"
        }`
      : ``;

    const pubParam1 = this.userContext.pubParam1
      ? { pub_param_1: this.userContext.pubParam1 }
      : {};
    const pubParam2 = this.userContext.pubParam2
      ? { pub_param_2: this.userContext.pubParam2 }
      : {};
    const data = {
      url: `${this.pageURL}${widgetParam}${urlMonitoringQueryStringParameters}`,
      dev: this.getDevice(),
      ...pubParam1,
      ...pubParam2,
      referrer: this.userContext.referrer,
    };

    const trafficSourceHeader = clearEmptyKeys({
      utm_source: this.publisherId,
      utm_traffic_type: this.userContext.trafficSource.trafficJoin,
    });
    const taxonomyContextHeader = clearEmptyKeys({
      site_id: this.siteId,
      vertical_id: this.verticalId,
    });
    const trafficHeader = clearEmptyKeys({
      url_utm_source: this.userContext.trafficSource.utmSource,
      utm_campaign: this.userContext.trafficSource.utmCampaign,
      utm_medium: this.userContext.trafficSource.utmMedium,
      utm_term: this.userContext.trafficSource.utmTerm,
      ad_id: this.userContext.trafficSource.adId,
      channel_click_id: this.userContext.trafficSource.channelClickId,
      t: this.userContext.trafficSource.trafficType,
      keyword_id: this.userContext.trafficSource.keywordGroupId,
      traffic_source_variant: this.userContext.trafficSource.trafficSource,
      ad_group_id: this.userContext.trafficSource.adGroupId,
      traffic_join: "Publishers",
      aff_traffic_join: this.userContext.trafficSource.trafficJoin,
      campaign_id: this.userContext.trafficSource.campaignId,
      source_join: this.publisherId,
      aff_source_join: this.userContext.trafficSource.source,
      topic: this.userContext.trafficSource.topic,
      network: this.userContext.trafficSource.network,
      match_type: this.userContext.trafficSource.matchType,
      channel_platform:
        this.userContext.visitorDevice.device?.type || "desktop",
    });

    const iid = this.getImpressionId();
    const uid = this.user.getUserId();

    const contextHeader = {
      uid,
      iid,
      ni_session_id: this.getSessionId(),
    };

    if (this.debugModeEnabled) {
      console.log({
        pageViewEvent: {
          data,
          contextHeader,
          trafficSourceHeader,
          taxonomyContextHeader,
          trafficHeader,
        },
      });
    }

    return this.sign(
      `${this.trackerUrl}/?ev=pv&iid=${iid}&uid=${uid}&data=${this.toBase64(
        data
      )}&tsh=${this.toBase64(trafficSourceHeader)}&tch=${this.toBase64(
        taxonomyContextHeader
      )}&th=${this.toBase64(trafficHeader)}&ctx=${this.toBase64(contextHeader)}`
    );
  };

  public getUIEventUrl(widgetId: string, event: UIEvent) {
    const widgetParam = widgetId
      ? `${this.pageURL.indexOf("?") > 0 ? "&" : "?"}wt=${widgetId}`
      : ``;
    const data = {
      events: [
        {
          event: "ui_event",
          attributes: {
            cms_site: 0,
            iid: this.getImpressionId(),
            category: event.eventCategory,
            action: event.eventAction,
            label: event.eventLabel,
            value: event.eventValue,
            non_interactive: !event.eventInteractive,
            eid: nanoid(20),
            uid: this.user.getUserId(),
            cms_platform: "xsite",
            data_url: `${this.pageURL}${widgetParam}`,
            data_user_agent: this.userContext.visitorDevice.userAgent,
            data_timestamp: new Date(),
          },
        },
      ],
    };

    const iid = this.getImpressionId();

    const uid = this.user.getUserId();
    const contextHeader = {
      uid,
      iid,
      ni_session_id: this.getSessionId(),
    };
    if (this.debugModeEnabled) {
      console.log({ uiEvent: { data, contextHeader } });
    }
    return this.sign(
      `${this.trackerUrl}/?data=${this.toBase64(data)}&ctx=${this.toBase64(
        contextHeader
      )}`
    );
  }

  public getPublisherWidgetInteractionEventUrl(
    config: AppConfigurations,
    event: PwiEvent
  ) {
    const iid = this.getImpressionId();
    const componentHeader = {
      comp_id: config?.widgetId,
      comp_iid: iid,
      comp_name: config?.widgetData?.widget?.name,
    };
    const contextHeader = {
      uid: this.user.getUserId(),
      iid,
      ni_session_id: this.getSessionId(),
    };
    const data = {
      cirrus_widget_id: config?.widgetData?.widget?.baseWidgetId,
      interaction_type: event.interactionType,
      interaction_result: event.interactionResult,
      question_id: event.questionId,
      question_name: event.questionName,
      number_of_products: event.numberOfProducts,
      data_url: `${this.pageURL}`,
      comp_layout: event.layout,
      products_results: event.productsResults,
      timestamp: Date.now(),
    };
    if (this.debugModeEnabled) {
      console.log({ pwiEvent: { data, contextHeader, componentHeader } });
    }

    return this.sign(
      `${this.trackerUrl}/?ev=pwi&data=${this.toBase64(
        data
      )}&ctx=${this.toBase64(contextHeader)}&ch=${this.toBase64(
        componentHeader
      )}`
    );
  }

  public getWidgetLoadEventUrl({
    widgetId,
    widgetType,
    widgetName,
    widgetLoadTime,
    widgetAppPath,
    slotsLoadTime,
    widgetCacheHit,
    slotsCacheHit,
  }: WidgetLoadEvent) {
    const data = {
      events: [
        {
          event: "publisher-widget-load",
          attributes: {
            widget_id: widgetId,
            widget_name: widgetName,
            widget_type: widgetType,
            widget_load_time: widgetLoadTime,
            widget_cache_hit: widgetCacheHit,
            slots_load_time: slotsLoadTime,
            slots_cache_hit: slotsCacheHit,
            widget_app_path: widgetAppPath,
            iid: this.getImpressionId(),
            uid: this.user.getUserId(),
          },
        },
      ],
    };

    const iid = this.getImpressionId();

    const uid = this.user.getUserId();
    const contextHeader = {
      uid,
      iid,
      ni_session_id: this.getSessionId(),
    };
    if (this.debugModeEnabled) {
      console.log({ publisherWidgetLoadEvent: { data, contextHeader } });
    }
    return this.sign(
      `${this.trackerUrl}/?data=${this.toBase64(data)}&ctx=${this.toBase64(
        contextHeader
      )}`
    );
  }

  public getXsiteComponentEventUrl(
    widgetId: string,
    {
      compIid,
      products,
      widgetType,
      baseWidgetId,
      productList,
      slotData,
    }: {
      compIid: string;
      widgetType: string;
      products: Array<{ position: number; product_id: number | string }>;
      baseWidgetId: string;
      productList: Pick<ProductList, "id" | "varId" | "ver">;
      slotData: SlotData;
    }
  ) {
    const taxonomyContextHeader = clearEmptyKeys({
      site_id: this.siteId,
      vertical_id: this.verticalId,
    });

    const productListHeader = {
      product_list_id: productList?.id,
      product_list_var_id: productList?.varId,
      product_list_var_version: productList?.ver,
    };

    const componentHeader = {
      slot_id: slotData?.slotId,
      comp_iid: compIid,
      comp_id: widgetId,
      comp_name: widgetType,
    };
    const data = {
      slot_index: slotData?.slotIndex,
      slot_role: slotData?.slotRole,
      pub_widget_id: baseWidgetId,
      products,
    };
    if (this.isXsite) {
      delete data.pub_widget_id;
    }

    const iid = this.getImpressionId();
    const uid = this.user.getUserId();

    const contextHeader = {
      uid,
      iid,
      ni_session_id: this.getSessionId(),
    };
    if (this.debugModeEnabled) {
      console.log({
        componentEvent: {
          data,
          contextHeader,
          taxonomyContextHeader,
          componentHeader,
          productListHeader,
        },
      });
    }

    return this.sign(
      `${this.trackerUrl}/?ev=c&data=${this.toBase64(
        data
      )}&iid=${iid}&uid=${uid}&tch=${this.toBase64(
        taxonomyContextHeader
      )}&ch=${this.toBase64(componentHeader)}&plh=${this.toBase64(
        productListHeader
      )}&ctx=${this.toBase64(contextHeader)}`
    );
  }

  public getExperimentEventUrl({
    experiment,
    variant,
    iid,
    uid,
  }: {
    experiment: Experiment;
    variant: ExperimentVariation;
    iid: string;
    uid: string;
  }) {
    const contextHeader = {
      uid,
      iid,
      ni_session_id: this.getSessionId(),
    };

    const data = {
      id: experiment.id,
      name: `${experiment.testKey} - ${experiment.name}`,
      version: experiment.ver,
      is_control: variant.isControlGroup,
      variant: variant.name,
      variation_id: variant.id,
    };

    if (this.debugModeEnabled) {
      console.log({
        componentEvent: {
          data,
          contextHeader,
        },
      });
    }
    return this.sign(
      `${this.trackerUrl}/?ev=xe&data=${this.toBase64(
        data
      )}&ctx=${this.toBase64(contextHeader)}`
    );
  }

  private bindUserInteraction() {
    const debouncedSessionExtend = debounce(this.extendSession, 1000, {
      maxWait: 5000,
    });
    window.addEventListener("mousemove", debouncedSessionExtend);
    window.addEventListener("scroll", debouncedSessionExtend);
  }

  private generateImpressionId() {
    return nanoid(20);
  }

  private toBase64(data: object) {
    return btoa(
      encodeURIComponent(JSON.stringify(data)).replace(
        /%([0-9A-F]{2})/g,
        function (t, e) {
          return String.fromCharCode(("0x" + e) as unknown as number);
        }
      )
    )
      .replace(/=/g, "")
      .replace(/\+/g, "-")
      .replace(/\//g, "_");
  }

  private getDevice() {
    return this.userContext.visitorDevice?.device?.type?.charAt(0);
  }

  private sign(url: string) {
    const token = md5(`${url}${window.navigator.userAgent.slice(0, 10)}`);
    return `${url}&t=${token}`;
  }
}
