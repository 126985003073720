import { logger } from "../logger/logger";
import { TrackerConfig } from "../models";
import { ClientMessenger } from "../services/messenger/ClientMessenger";
import { HostMessenger } from "../services/messenger/HostMessenger";
import { createUserContext } from "../services/tracker/createUserContext";
import { Tracker } from "../services/tracker/tracker";
import {
  isXsite,
  pubParam1,
  pubParam2,
  wiid,
  wuid,
  isDebugModeEnabled,
} from "./currentScriptData";
import { insertScript } from "./domUtils";

declare global {
  const __EVENT_PATH__: string;
}

interface ImpressionEventTriggerOptions {
  isMonitoring?: boolean;
  monitoringSource?: string;
}

const eventPath = __EVENT_PATH__;
const hostMessenger = new HostMessenger();

class ImpressionEventTrigger {
  constructor(public isTriggered: boolean = false) {}

  public trigger(
    tracker: Tracker,
    window: Window,
    options: ImpressionEventTriggerOptions = {}
  ) {
    insertScript(tracker.getPageViewUrl("loader", options), undefined, window);
    insertScript(tracker.getClientImpressionUrl("loader"), undefined, window);

    this.isTriggered = true;
  }
}

const getTracker = function ({
  verticalId,
  siteId,
  source,
}: {
  verticalId: string;
  siteId: number;
  source: string;
}) {
  if (getTracker.tracker) {
    return getTracker.tracker;
  }

  const { context, pageUrl, isDebugModeEnabled } = getTrackerConfig();
  const tracker = new Tracker({
    pageUrl,
    trackerUrl: eventPath,
    siteId,
    verticalId,
    context,
    publisherId: source,
    isXsite,
    isDebugModeEnabled,
  });

  return (getTracker.tracker = tracker);
} as {
  (data: { verticalId: string; siteId: number; source: string }): Tracker;
  tracker: Tracker;
};

const getTrackerConfig = function (): TrackerConfig {
  if (getTrackerConfig.config) {
    return getTrackerConfig.config;
  }

  const userContext = createUserContext({
    iid: wiid,
    uid: wuid,
    _pubParam1: pubParam1,
    _pubParam2: pubParam2,
  });
  // this tracker is needed to create a tracker context which will include iid and uid generated by tracker
  // thats why pubId, vertId, siteId are null

  const tracker = new Tracker({
    context: userContext,
    trackerUrl: eventPath,
    publisherId: null,
    siteId: null,
    verticalId: null,
    isXsite,
    isDebugModeEnabled,
  });
  const config: TrackerConfig = {
    pageUrl: tracker.getPageUrl(),
    context: tracker.getUserContext(),
    isXsite,
    isDebugModeEnabled,
  };

  return (getTrackerConfig.config = config);
} as {
  (): TrackerConfig;
  config: TrackerConfig;
};

function bindNestedLoaderTracking() {
  const clientMessenger = new ClientMessenger({ widgetKey: "nested" });
  hostMessenger.on("*", (msg) => {
    clientMessenger.sendMessage(msg);
  });
}

function bindLoaderTracking() {
  hostMessenger.on("track", function (msg) {
    insertScript(msg.data.data, undefined, window);
  });

  hostMessenger.on("log", function (msg) {
    logger.log(msg.data.message, msg.data.context, msg.data.status);
  });
}

function addExternalEventListener(
  callback: (event: any, widgetKey: string) => void
) {
  hostMessenger.on("pwiEvent", function (msg) {
    callback(msg.data, msg.widgetKey);
  });
}

function initLoaderTracking(nested: boolean) {
  if (!nested) {
    bindLoaderTracking();
  } else {
    bindNestedLoaderTracking();
  }
}

// if we have wuid and wiid that means that impression event is already triggered by the parent
const isImpressionTriggered = !!(wuid && wiid);
const impressionEventTrigger = new ImpressionEventTrigger(
  isImpressionTriggered
);

export {
  getTracker,
  getTrackerConfig,
  initLoaderTracking,
  addExternalEventListener,
  impressionEventTrigger,
};
