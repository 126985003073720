import { isLocalStorageSupported, localStorage } from "./localStorage";
import { inMemoryStorage } from "./inMemoryStorage";
import { Storage } from "./types";
import { logger } from "../../logger/logger";

function getStorage() {
  if (!isLocalStorageSupported()) {
    logger.warn("localStorage is not supported", {});
    return inMemoryStorage;
  }

  return localStorage;
}

const storage: Storage = getStorage();

export { storage };
