import { WidgetDataAPIResponse } from "../models";
import { ApiError } from "./ApiError";
import { staticData } from "./staticData";
import { generateToken } from "./utils";
import { logger } from "../logger/logger";

export interface GetWidgetDataPayload {
  appInstanceId: string;
  publisherId: string;
  wuid: string;
  wiid: string;
  apiBaseUrl: string;
  redirectUrl: string;
  isMonitoring: boolean;
  monitoringSource: string;
}

const API_PATH = __API_PATH__;
const versionTag = __VERSION_TAG__;
const REDIRECT_URL = __REDIRECT_PATH__;
const BUILD = __BUILD__;
const PULL = __PULL__;

export interface GetWidgetResponse {
  body: WidgetDataAPIResponse;
  headers: Headers;
}

export const getWidgetData = async ({
  publisherId,
  appInstanceId,
  wuid,
  wiid,
  apiBaseUrl = API_PATH,
  redirectUrl = REDIRECT_URL,
  isMonitoring = false,
  monitoringSource,
}: GetWidgetDataPayload): Promise<GetWidgetResponse> => {
  const cacheId = `${publisherId}_${appInstanceId}`;

  if (staticData.isStaticId(cacheId)) {
    return { body: await staticData.get(cacheId), headers: new Headers() };
  }

  const apiUrl = getApiURL({
    redirectUrl,
    isMonitoring,
    monitoringSource,
    apiBaseUrl,
    appInstanceId,
  });

  const requestConfig: RequestInit = {
    headers: {
      Authorization: `Bearer ${generateToken()}`,
      wuid,
      "x-ni-uid": wuid,
      "x-ni-iid": wiid,
    },
    credentials: "omit",
  };

  let res: Response;

  try {
    res = await fetch(apiUrl, requestConfig);
  } catch (e) {
    logger.error(`API Fetch error: ${e.message}`, {
      exception: e,
      apiUrl: apiUrl.toString(),
      requestConfig,
      networkStatus: {
        downlink: (navigator as any)?.connection?.downlink,
        rtt: (navigator as any)?.connection?.rtt,
        onLine: navigator?.onLine,
      },
    });

    throw e;
  }

  const jsonResponse = await res.json();

  if (!res.ok) {
    throw new ApiError("API Request Failure", {
      status: res.status,
      response: jsonResponse,
    });
  }

  return {
    body: jsonResponse?.content?.data as WidgetDataAPIResponse,
    headers: res.headers,
  };
};

function getApiURL({
  redirectUrl,
  isMonitoring,
  monitoringSource,
  apiBaseUrl,
  appInstanceId,
}: {
  redirectUrl: string;
  isMonitoring: boolean;
  monitoringSource: string;
  apiBaseUrl: string;
  appInstanceId: string;
}): URL {
  const redirectHost = new URL(redirectUrl).host;

  const query = {
    originalHost: redirectHost,
    v: versionTag,
  };

  if (isMonitoring) {
    query["monitoring"] = "1";
    if (monitoringSource) {
      query["monitoring_source"] = monitoringSource;
    }
  }

  if (!!BUILD && !!PULL) {
    query["pull"] = PULL;
    query["build"] = BUILD;
  }

  const apiUrl = new URL(
    `${apiBaseUrl}/publishers/publisher-widget/${appInstanceId}`
  );

  apiUrl.search = new URLSearchParams(query).toString();

  return apiUrl;
}
