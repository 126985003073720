import { Storage } from "./types";

const map = new Map();

const inMemoryStorage: Storage = {
  setItem(name: string, value: string) {
    map.set(name, value);
  },

  getItem(name: string) {
    return map.get(name);
  },
};

export { inMemoryStorage };
