import { Event, Message, PwiEvent, TrackerEventType, UIEvent } from "./common";

interface Options {
  widgetKey: string;
}

class ClientMessenger {
  private widgetKey: string;

  constructor(options: Options) {
    this.widgetKey = options.widgetKey;
  }

  public sendTrackEvent(type: TrackerEventType, data?: string) {
    const event = new Event(type, data);
    const message = new Message("track", event, { widgetKey: this.widgetKey });

    this.sendMessage(message);
  }

  public sendUiEvent(data: Partial<PwiEvent>) {
    const message = new Message("pwiEvent", data, {
      widgetKey: this.widgetKey,
    });

    this.sendMessage(message);
  }

  // we need to think about creating a general log type (data) and use it everywhere
  // same as with track event
  public sendLog(data: any) {
    const message = new Message("log", data, { widgetKey: this.widgetKey });

    this.sendMessage(message);
  }

  public sendMessage<M extends Message<any>>(message: M) {
    window.parent.postMessage(JSON.stringify(message), "*");
  }
}

export { ClientMessenger, Options as ClientMessengerOptions };
