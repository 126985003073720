import { createWidgetListResolver } from "../experiments/executor";
import { Experiment, PublisherWidgetList } from "../models";
import { Tracker } from "../services/tracker/tracker";
import { insertScript } from "./domUtils";
import { getTrackerConfig } from "./tracking";
import { setSlotsLoadingStatus, getSlotExperimentEventData } from "./utils";

const trackerConfig = getTrackerConfig();

// i created a new tracker here because i can't use getTracker function since it's return a singleton
// and to properly call that function i need a vertical/site ids that are not available at this point
// if i call it without that every places in the loader will receive the tracker without this data
const tracker = new Tracker({
  ...trackerConfig,
  trackerUrl: __EVENT_PATH__,
} as any);

export interface Slot {
  id: string;
  name: string;
  publisherContextId: string;
  role: string;
  widgetIds: Array<string | null>;
  widgetListId: string;
}

export interface SlotResponse {
  publisherContextId: string;
  publisherContextUrlId: string;
  publisherSlots: Slot[];
  url: string;
  publisherWidgetLists: PublisherWidgetList[];
  experiments: Experiment[];
}

export interface TransformedSlots {
  [role: string]: {
    slotRole: string;
    slotId: string;
    widgetIds: string[];
    processedIndex: number;
    experimentEventData?: ReturnType<typeof getSlotExperimentEventData>;
  };
}

export function mapSlots(
  slots: SlotResponse,
  { iid, uid }: { iid: string; uid: string }
): TransformedSlots {
  const { publisherSlots, experiments = [], publisherWidgetLists = [] } = slots;
  const transformedSlots: TransformedSlots = {};
  const experimentsResolver = createWidgetListResolver({
    experiments,
    publisherWidgetLists,
    userId: uid,
  });

  publisherSlots.forEach((item) => {
    const { role, widgetIds, id } = item;
    const resolvedWidgetList =
      experimentsResolver.resolveWidgetListVariant(item);

    const slotWidgetIds =
      resolvedWidgetList?.widgetList?.widgetIds ?? widgetIds;

    if ((role || role === "") && !transformedSlots[role]) {
      const experimentEventData = getSlotExperimentEventData(
        resolvedWidgetList,
        {
          iid,
          uid,
        }
      );

      transformedSlots[role] = {
        slotRole: role,
        slotId: id,
        widgetIds: [...slotWidgetIds],
        processedIndex: 0,
        experimentEventData,
      };
    }
  });

  return transformedSlots;
}

export const handleSlots = (slots: TransformedSlots) => {
  const slotElements = document.querySelectorAll(
    "[data-ni-slot]:not([data-processed])"
  ) as NodeListOf<HTMLElement>;

  slotElements.forEach((slotElement) => {
    const slotRole = slotElement.dataset.niSlot;
    const currentSlot = slots[slotRole];
    if (
      currentSlot &&
      currentSlot.processedIndex < currentSlot.widgetIds.length
    ) {
      slotElement.setAttribute(
        "data-ni-instance-id",
        currentSlot.widgetIds[currentSlot.processedIndex]
      );
      slotElement.setAttribute(
        "data-ni-slot-index",
        `${currentSlot.processedIndex}`
      );
      slotElement.setAttribute("data-ni-slot-id", `${currentSlot.slotId}`);

      currentSlot.processedIndex++;

      // need to fire only ones for slot role
      if (currentSlot.experimentEventData) {
        insertScript(
          tracker.getExperimentEventUrl(currentSlot.experimentEventData),
          undefined,
          window
        );
        currentSlot.experimentEventData = null;
      }
    }
  });

  setSlotsLoadingStatus(false);
};
