import { getSlots } from "../api/getSlots";
import { logger } from "../logger/logger";
import { publisherId, contextKey } from "./currentScriptData";
import { mapSlots } from "./handleSlots";
import { getTrackerConfig } from "./tracking";

const trackerConfig = getTrackerConfig();
const uid = trackerConfig.context.visitor.uid;
const iid = trackerConfig.context.impression.iid;

function resolveContextKey() {
  return contextKey;
}

async function resolveSlots() {
  let slots;
  try {
    const contextKey = resolveContextKey();
    const slotsResults = await getSlots({ publisherId, contextKey }, iid, uid);
    // if we are not getting any data it is fine, but we need to trigger an info
    if (slotsResults?.data) {
      slots = mapSlots(slotsResults.data, { iid, uid });
    } else {
      logger.info(`The slots were not found for`, {
        publisherId,
        iid,
        uid,
      });
    }
    trackerConfig.slotsMetadata = slotsResults.fetchingMetadata;
  } catch (err) {
    logger.error(err.message, { publisherId, ...err?.context });
  }

  return slots;
}

export { resolveSlots };
