import { getWidgetData, GetWidgetDataPayload } from "../api";
import { WidgetDataAPIResponse } from "../models";
import { extractTrackerData } from "./utils";

interface EnrichedWidgetData {
  path: string;
  data?: WidgetDataAPIResponse;
  headers?: Headers;
  trackerData: ReturnType<typeof extractTrackerData>;
}

async function resolveBucketData(
  params: GetWidgetDataPayload
): Promise<EnrichedWidgetData> {
  const { body, headers } = await getWidgetData(params);

  if (!body?.widget?.appPath) {
    throw new Error("missing appPath");
  }

  return {
    path: getWidgetPath(body),
    data: body,
    headers,
    trackerData: extractTrackerData(body),
  };
}

function getWidgetPath(data: WidgetDataAPIResponse) {
  if (process.env.NODE_ENV === "development") {
    // TODO - rename all widget folders to match the widget type in DB (kebab-case with 'publisher' prefix) and remove this
    const widgetType = data.widget.type
      .split("-")
      .filter((str) => str !== "publisher")
      .map((str) => str[0].toLocaleUpperCase() + str.slice(1))
      .join("");

    return `/${widgetType}/app.js`;
  }

  const basePath = __BASE_PATH__;
  const versionTag = __VERSION_TAG__;

  const url = new URL(data.widget.appPath);
  const pathname = url.pathname
    .replace(/^\/apps/, "")
    .replace("latest", versionTag);

  return `${basePath}${pathname}`;
}

export { resolveBucketData };
