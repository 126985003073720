import { ThemeV2 } from "../models";
import {
  FontConfig,
  Fonts,
  fontsMap,
  fontsMapV2,
  Preset,
  presetFonts,
  Theme,
} from "../styles/fonts";

function onDomReady(
  callback: () => void,
  { win = window, doc = document } = {}
) {
  if (doc.readyState === "complete") {
    callback();
  } else {
    win.addEventListener("load", callback);
  }
}

function insertLinkTag(
  attrs: FontConfig,
  window: Window,
  onLoad?: () => void,
  onError?: () => void
) {
  const link = window.document.createElement("link");
  const head = window.document.getElementsByTagName("head")[0];

  Object.keys(attrs).forEach((key) => {
    link[key] = attrs[key];
  });

  link.onload = onLoad;
  link.onerror = onError;

  head.appendChild(link);
}

function insertStyleTag(attrs: FontConfig, window: Window) {
  const head = window.document.getElementsByTagName("head")[0];
  const style = window.document.createElement("style");
  style.appendChild(document.createTextNode(attrs.css));
  head.appendChild(style);
}

function insertFonts(theme: Theme, window: Window) {
  (fontsMap[theme] || fontsMap.light).map((config) => {
    if (config.css) {
      return insertStyleTag(config, window);
    }
    insertLinkTag(config, window);
  });
}

function insertFontsV2(theme: ThemeV2, preset: Preset, window: Window) {
  const insertedSet = new Set();

  const fonts = [
    theme?.fontFamilyPrimary,
    theme?.h1FontFamily,
    theme?.h2FontFamily,
    presetFonts[preset],
    presetFonts.default,
  ].filter(Boolean) as Fonts[];

  const links = fonts
    .flatMap((font) => {
      const fontConfig = fontsMapV2[font];
      return fontConfig?.fontLinks;
    })
    .filter(Boolean);

  links.forEach((linkConfig: FontConfig) => {
    const linkKey = linkConfig.href || linkConfig.css;

    if (insertedSet.has(linkKey)) {
      return;
    }

    insertedSet.add(linkKey);

    const insertFn = linkConfig.css ? insertStyleTag : insertLinkTag;
    insertFn(linkConfig, window);
  });
}

function insertScript(
  src: string,
  dataKey: string,
  window: Window,
  onLoad?: () => void
) {
  const script = window.document.createElement("script");
  const head = window.document.getElementsByTagName("head")[0];

  script.onload = onLoad;
  if (dataKey) {
    script.id = dataKey;
  }
  script.src = src;
  script.async = true;

  head.appendChild(script);
}

function createIframe($container: HTMLElement) {
  const iframe = document.createElement("iframe");
  iframe.style.width = "1px";
  iframe.style.minWidth = "100%";
  iframe.style.border = "none";
  iframe.style.height = "200px";

  return iframe;
}

async function insertIframe(
  wrappingElement: HTMLElement,
  iframe: HTMLIFrameElement
) {
  return new Promise<void>((resolve) => {
    wrappingElement.appendChild(iframe);

    onDomReady(resolve, {
      win: iframe.contentWindow as Window & typeof globalThis,
      doc: iframe.contentDocument,
    });
  });
}

function applyStylesToWrapper(
  wrappingElement: HTMLElement,
  styles?: { boxShadow: string; borderRadius: string }
) {
  wrappingElement.style.width = "100%";
  wrappingElement.style.overflow = "hidden";
  wrappingElement.style.boxSizing = "border-box";

  if (styles) {
    wrappingElement.style.boxShadow = styles.boxShadow || "";
    wrappingElement.style.borderRadius = styles.borderRadius || "";
  }

  if (wrappingElement.dataset.borderRadius) {
    wrappingElement.style.borderRadius = wrappingElement.dataset.borderRadius;
  }

  if (wrappingElement.dataset.boxShadow) {
    wrappingElement.style.boxShadow = wrappingElement.dataset.boxShadow;
  }
}

export {
  onDomReady,
  insertFonts,
  insertFontsV2,
  insertScript,
  createIframe,
  insertIframe,
  applyStylesToWrapper,
};
