import { WidgetDataAPIResponse } from "../models";
import { ApiError } from "./ApiError";

declare global {
  const __STATIC_DATA_MAP__: Record<string, string>;
}
const staticDataPathMap = __STATIC_DATA_MAP__;
const versionTag = __VERSION_TAG__;

class StaticData {
  private cache: Map<string, WidgetDataAPIResponse>;

  constructor() {
    this.cache = new Map();
  }

  public isStaticId(id: string) {
    return !!this.getStaticDataPath(id);
  }

  public async get(id: string): Promise<WidgetDataAPIResponse> {
    if (this.cache.has(id)) {
      return this.cache.get(id);
    }

    const newData = await this.fetchStaticData(id);

    this.cache.set(id, newData);

    return newData;
  }

  private getStaticDataPath(id: string) {
    return staticDataPathMap[id];
  }

  private getUrl(id: string) {
    const relativePath = this.getStaticDataPath(id);

    return `${__BASE_PATH__}/fixtures/${versionTag}/${relativePath}`;
  }

  private async fetchStaticData(id: string) {
    const res = await fetch(this.getUrl(id), {
      credentials: "omit",
    });

    const jsonResponse = await res.json();

    if (!res.ok) {
      throw new ApiError("Static data request failure", {
        status: res.status,
        response: jsonResponse,
      });
    }

    return jsonResponse as WidgetDataAPIResponse;
  }
}

const staticData = new StaticData();

export { staticData };
