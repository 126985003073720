import isEqualWith from "lodash.isequalwith";

export type ComparerType = typeof valueComparer[keyof typeof valueComparer];

function ignoreCaseCustomizer(left: string, right: string) {
  if (typeof left === "string" && typeof right === "string") {
    return left.toLowerCase() === right.toLowerCase();
  }
  return undefined;
}

const valueComparer = {
  /**
   * equals
   * @param contextValue
   * @param conditionValue
   */
  equals(contextValue: string | boolean, conditionValue: string | boolean) {
    return isEqualWith(contextValue, conditionValue, ignoreCaseCustomizer);
  },

  /**
   * in
   * @param {string} contextValue
   * @param {string[]} conditionValue
   * @return {boolean}
   */
  in(contextValue: string, conditionValue: string[]) {
    if (Array.isArray(conditionValue)) {
      return conditionValue.some((value) => {
        if (Array.isArray(contextValue)) {
          return contextValue.some((contextVal) =>
            valueComparer.equals(contextVal, value)
          );
        }
        return valueComparer.equals(contextValue, value);
      });
    }
    console.error(
      `condition comparer-in: invalid array value: ('${conditionValue}'). Should be an array`
    );
    return false;
  },

  /**
   * notIn
   * @param contextValue
   * @param conditionValue
   * @return {boolean}
   */
  notIn(contextValue: string, conditionValue: string[]) {
    return !valueComparer.in(contextValue, conditionValue);
  },
};

export { valueComparer };
