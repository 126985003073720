/* tslint:disable:max-classes-per-file */
interface MessageContext {
  widgetKey: string;
}

export interface UIEvent {
  eventCategory: string;
  eventAction: string;
  eventLabel: string;
  eventValue: number | string;
  eventInteractive: boolean;
}

export interface PwiEvent {
  interactionType: string;
  interactionResult: string | boolean;
  questionId: string;
  questionName: string;
  numberOfProducts?: number;
  layout: string;
  productsResults?: number[];
}

export interface WidgetLoadEvent {
  widgetId: string;
  widgetType: string;
  widgetName: string;
  widgetAppPath: string;
  widgetLoadTime: number;
  slotsLoadTime: number;
  widgetCacheHit: boolean;
  slotsCacheHit: boolean;
}

export type MessageType = "track" | "pwiEvent" | "log";
export type TrackerEventType =
  | "pageView"
  | "clientImpression"
  | "xsiteComponent"
  | "pwiEvent";

export const messageToken = "ni-widget-token";

export class Message<Data> {
  public widgetKey: string;
  public token = messageToken;

  constructor(
    public type: MessageType,
    public data: Data,
    context?: MessageContext
  ) {
    this.widgetKey = context?.widgetKey;
  }
}

export class Event<Type extends string, Data> {
  constructor(public type: Type, public data?: Data) {}
}
