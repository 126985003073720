import { trackerUidGenerator } from "../../utils/uidGenerator";
import { storage } from "../storage/storage";

export class User {
  private storageIdKey = "__ni-w-uid";
  private storageTimestampKey = "__ni-w-time";
  private expireTime = 1000 * 60 * 60 * 24 * 30;

  constructor(private uid?: string) {
    if (!uid) {
      this.uid = this.generateUserId();
    }
  }

  public getUserId() {
    return this.uid;
  }

  public generateUserId() {
    const uid = storage.getItem(this.storageIdKey);
    const timestamp = storage.getItem(this.storageTimestampKey);
    const newTimestamp = Date.now();

    storage.setItem(this.storageTimestampKey, newTimestamp.toString());

    if (
      !uid ||
      !timestamp ||
      newTimestamp - Number(timestamp) >= this.expireTime
    ) {
      const newUid = trackerUidGenerator();

      storage.setItem(this.storageIdKey, newUid);

      return newUid;
    }

    return uid;
  }
}
