// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "[data-ni-link]:not([data-processed]),[data-ni-instance-id]:not([data-processed]){animation-duration:.001s;animation-name:loader-nodeInserted-ReFKl}[data-ni-instance-id]:not([data-loading-status]){height:200px}[data-ni-slot][data-slot-loading=loading],[data-ni-instance-id][data-loading-status=loading]{height:200px;width:100%;display:flex;align-items:center;justify-content:center}[data-ni-slot][data-slot-loading=loading] iframe,[data-ni-instance-id][data-loading-status=loading] iframe{display:none}[data-ni-slot][data-slot-loading=loading]:after,[data-ni-instance-id][data-loading-status=loading]:after{display:block;border-radius:50%;width:100px;height:100px;content:\" \";font-size:10px;position:relative;text-indent:-9999em;border-top:10px solid #f0f0f0;border-right:10px solid #f0f0f0;border-bottom:10px solid #f0f0f0;border-left:10px solid #aaa;-webkit-transform:translateZ(0);-ms-transform:translateZ(0);transform:translateZ(0);animation:loader-spinning-zZH2F 1.1s infinite linear}@keyframes loader-spinning-zZH2F{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes loader-nodeInserted-ReFKl{from{opacity:.99}to{opacity:1}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nodeInserted": "loader-nodeInserted-ReFKl",
	"spinning": "loader-spinning-zZH2F"
};
export default ___CSS_LOADER_EXPORT___;
