import { createConsoleTransport } from "./transports/console";
import { createDataDogTransfer } from "./transports/datadog";

declare global {
  const __DD_TOKEN__: string;
  const __ENV__: string;
}

const getDDConfig = () => {
  const baseService = `xsite-publishers-client`;
  const service = `${baseService}-${__ENV__}`.toLowerCase();
  return {
    clientToken: __DD_TOKEN__,
    service,
  };
};

const createLoaderTransports = () => {
  const transports = [];
  if (process.env.NODE_ENV !== "production") {
    transports.push(createConsoleTransport());
  }

  if (__DD_TOKEN__) {
    transports.push(createDataDogTransfer(getDDConfig()));
  }

  return transports;
};

export { createLoaderTransports };
