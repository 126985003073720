import { isDesktop, isTablet, isMobile } from "react-device-detect";

type RuleContext = ReturnType<typeof buildContext>;

const getDevice = () => {
  if (isDesktop) {
    return "desktop";
  } else if (isTablet) {
    return "tablet";
  } else if (isMobile) {
    return "mobile";
  }

  return "desktop";
};

function buildContext() {
  const context = {
    device: getDevice(),
  };

  return context;
}

export { buildContext, RuleContext };
