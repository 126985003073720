import { Slot } from "../loader/handleSlots";
import {
  Experiment,
  ExperimentVariation,
  PublisherWidgetList,
} from "../models";
// @ts-ignore:next-line
import variants from "ni-constants/lib/helpers/constants-variants";
import { getMatchedExperiment } from "./experiment-matcher";
import { buildContext } from "./context-builder";

const controlGroupType = "controlGroup";
const publisherWidgetListType = "publisherWidgetList";

// https://github.com/Natural-Intelligence/ni-rne-evaluation-engine/blob/master/lib/helpers/hash-helper.js#L29C1-L36C2
/* tslint:disable:no-bitwise */
function sdbmCode(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = char + (hash << 6) + (hash << 16) - hash;
  }
  const int32 = hash >>> 0; // make it unsigned 32 bit integer
  return int32;
}
/* tslint:enable:no-bitwise */

function getVariation(experiment: Experiment, userId: string) {
  const { ranges, variations } = experiment;
  const raffleNumber = sdbmCode(`${userId}.${experiment.id.toString()}`);
  const variantId = ranges.find(
    (range) => raffleNumber <= range.to
  )?.variationId;

  if (!variantId) {
    throw new Error(
      `Can't find a variant, experimentId = ${experiment.id}, userId = ${userId}`
    );
  }

  const variant = variations.find((variant) => variant.id === variantId);

  if (!variant) {
    throw new Error(
      `Variant in experiment is not found experimentId = ${experiment.id}, userId = ${userId}, variantId = ${variantId}`
    );
  }

  return variant;
}

function extractManipulator(variant: ExperimentVariation) {
  if (variant.isControlGroup) {
    return { type: controlGroupType } as const;
  }

  // for now each variant can have only 1 manipulator
  return variant.manipulationsData[0];
}

function createPublisherListMatcher(toMatch: Partial<PublisherWidgetList>) {
  const fields = Object.keys(toMatch);
  return (list: PublisherWidgetList) =>
    fields.every((fieldName) => list[fieldName] === toMatch[fieldName]);
}

function resolveWidgetListVariant({
  experiment,
  userId,
  slot,
  widgetLists,
}: {
  experiment: Experiment;
  userId: string;
  slot: Slot;
  widgetLists: PublisherWidgetList[];
}) {
  const variant = getVariation(experiment, userId);
  const manipulator = extractManipulator(variant);

  let widgetListMatcher;

  if (manipulator.type === controlGroupType) {
    widgetListMatcher = createPublisherListMatcher({
      varType: variants.defaultVarTypeName,
      id: slot.widgetListId,
    });
  } else if (manipulator.type === publisherWidgetListType) {
    widgetListMatcher = createPublisherListMatcher({
      id: manipulator.data.id,
      varId: manipulator.data.varId,
    });
  } else {
    throw new Error(
      `Manipulator is not supported, type = ${manipulator.type}, variantId = ${variant.id}`
    );
  }

  const widgetList = widgetLists.find(widgetListMatcher);

  return {
    widgetList,
    variant,
    experiment,
  };
}

function buildExperimentsBySlotIdMap(experiments: Experiment[]) {
  const map = new Map<string, Experiment[]>();

  experiments.forEach((experiment) => {
    const experimentsForSlot =
      map.get(experiment.context.publisherSlotId) || [];

    map.set(
      experiment.context.publisherSlotId,
      experimentsForSlot.concat(experiment)
    );
  });

  return map;
}

function createWidgetListResolver({
  experiments,
  publisherWidgetLists,
  userId,
}: {
  experiments: Experiment[];
  userId: string;
  publisherWidgetLists: PublisherWidgetList[];
}) {
  const experimentsBySlotIdMap = buildExperimentsBySlotIdMap(experiments);
  const context = buildContext();

  return {
    resolveWidgetListVariant(slot: Slot) {
      const experiments = experimentsBySlotIdMap.get(slot.id);
      const defaultMatcher = createPublisherListMatcher({
        varType: variants.defaultVarTypeName,
        id: slot.widgetListId,
      });
      const experiment = getMatchedExperiment(experiments, context);

      if (!experiment) {
        return {
          widgetList: publisherWidgetLists.find(defaultMatcher),
          variant: null,
          experiment: null,
        };
      } else {
        return resolveWidgetListVariant({
          experiment,
          userId,
          slot,
          widgetLists: publisherWidgetLists,
        });
      }
    },
  };
}

export { createWidgetListResolver };
