export const hgsFontCss = `
@font-face {
  font-family: "hurmegeometricsans_no3_6";
  src: url("//umbrella.data.naturalint.com/production/fonts/hgs/v2/regular.woff2")
      format("woff2"),
    url("//umbrella.data.naturalint.com/production/fonts/hgs/regular.woff")
      format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "hurmegeometricsans_no3_6";
  src: url("//umbrella.data.naturalint.com/production/fonts/hgs/v2/semibold.woff2")
      format("woff2"),
    url("//umbrella.data.naturalint.com/production/fonts/hgs/semibold.woff")
      format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "hurmegeometricsans_no3_6";
  src: url("//umbrella.data.naturalint.com/production/fonts/hgs/v2/bold.woff2")
      format("woff2"),
    url("//umbrella.data.naturalint.com/production/fonts/hgs/bold.woff")
      format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
`;
