import {
  Event,
  Message,
  messageToken,
  MessageType,
  TrackerEventType,
} from "./common";

type AllMessageTypes = MessageType | typeof allEvents;

type MessageData<T extends AllMessageTypes> = T extends "track"
  ? Message<Event<TrackerEventType, string>>
  : Message<any>;

type Callback<T extends AllMessageTypes> = (message: MessageData<T>) => any;

const allEvents = "*";

class HostMessenger {
  private listeners = new Map<string, Map<number, Callback<AllMessageTypes>>>();
  private index = 0;

  constructor() {
    window.addEventListener("message", this.onMessage.bind(this));
  }

  public on<T extends AllMessageTypes>(type: T, callback: Callback<T>) {
    const index = this.index++;

    if (!this.listeners.has(type)) {
      this.listeners.set(type, new Map());
    }

    this.listeners.get(type).set(index, callback);

    return () => this.listeners.get(type).delete(index);
  }

  private onMessage(message: MessageEvent) {
    const msg = this.parseMessage(message);

    if (!msg) {
      return;
    }

    const listenersForType = [
      this.listeners.get(allEvents),
      this.listeners.get(msg.type),
    ].filter(Boolean);

    for (const listenersMap of listenersForType) {
      for (const callback of listenersMap.values()) {
        callback(msg);
      }
    }
  }

  private validateMessage(message: Message<any>) {
    return message.token === messageToken;
  }

  private parseMessage(message: MessageEvent): Message<any> {
    try {
      const msg = JSON.parse(message.data);

      if (this.validateMessage(msg)) {
        return msg;
      }

      return null;
    } catch (err) {
      return null;
    }
  }
}

export { HostMessenger };
