import { datadogLogs, LogsInitConfiguration } from "@datadog/browser-logs";
import { Transport } from "../logger";

const createDataDogTransfer = (config: LogsInitConfiguration): Transport => {
  datadogLogs.init({
    forwardErrorsToLogs: false,
    site: "datadoghq.com",
    service: "xsite-publishers-client-prod",
    sampleRate: 100,
    ...config,
  });

  return datadogLogs.logger;
};

export { createDataDogTransfer };
