import UAParser from "ua-parser-js";
import userSourceService from "ni-node-locator/lib/services/user-source-service";
import { trafficTypeMapper } from "ni-node-locator/lib/helpers/mappers";
import { Context } from "../../models";

function extractKwd(kwdString: string) {
  if (!kwdString) {
    return kwdString;
  }
  if (!kwdString.includes("kwd-")) {
    return kwdString;
  }
  return kwdString.split("kwd-")[1];
}

function extractKwdFromBtarId(kwdString: string) {
  if (!kwdString) {
    return kwdString;
  }
  if (!kwdString.includes("kwd-")) {
    return kwdString;
  }
  const value = kwdString.split("kwd-")[1];
  if (!value.includes(":")) {
    return value;
  }
  return value.split(":")[0];
}

export function getTrafficSource(
  referrer: string,
  query: string
): Context["trafficSource"] {
  const params = new URLSearchParams(query);
  // because of lint
  const [source, channel] = userSourceService.getSourceAndChannel(
    referrer,
    query
  );

  return {
    source,
    channel,
    utmSource: params.get("utm_source"),
    kw: params.get("kw") || params.get("bkw"),
    matchType: params.get("m"),
    adGroupId:
      params.get("adgroupid") ||
      params.get("bagid") ||
      params.get("ad_group_id") ||
      params.get("adgid"),
    trafficSource: params.get("ts"),
    keywordGroupId:
      extractKwd(params.get("targetid")) ||
      extractKwdFromBtarId(params.get("btarid")),
    topic: params.get("topic"),
    channelClickId:
      params.get("gclid") ||
      params.get("msclkid") ||
      params.get("fbclid") ||
      params.get("taboolaclickid") ||
      params.get("ob_click_id") ||
      params.get("ClickID") ||
      params.get("pc") ||
      params.get("rc_uuid") ||
      params.get("yclid") ||
      params.get("appsflyerId") ||
      params.get("adclid"),
    adId: params.get("ad_id") || params.get("c"),
    campaignId:
      params.get("bcampid") ||
      params.get("campaignid") ||
      params.get("taboola_campaign") ||
      params.get("campid") ||
      params.get("camid") ||
      params.get("campaign_id"),
    trafficType: params.get("t") || params.get("bt"),
    network: params.get("network"),
    targetingPlacement: params.get("p") || params.get("placement"),
    utmCampaign: params.get("utm_campaign"),
    utmTerm: params.get("utm_term"),
    adsetId: params.get("adset_id"),
    utmContent: params.get("utm_content"),
    siteSourceName: params.get("site_source_name"),
    utmMedium: params.get("utm_medium"),
    trafficJoin: trafficTypeMapper[channel?.toLowerCase()] ?? channel,
  };
}

export function createUserContext({
  uid,
  iid,
  _pubParam1,
  _pubParam2,
}: {
  uid: string;
  iid: string;
  _pubParam1?: string;
  _pubParam2?: string;
}): Context {
  const query = window.location.search;
  const referrer = document.referrer;
  const parser = new UAParser();
  const result = parser.getResult();
  const pubParam1 = _pubParam1 ? { pubParam1: _pubParam1 } : {};
  const pubParam2 = _pubParam2 ? { pubParam2: _pubParam2 } : {};
  const trafficSource = getTrafficSource(referrer, query);
  return {
    visitorDevice: {
      browser: {
        family: result.browser.name,
        version: result.browser.version,
      },
      device: {
        family: result.device.vendor,
        type: result.device.type,
      },
      os: {
        family: result.os.name,
        version: result.os.version,
      },
      userAgent: result.ua,
      url: window.location.href,
      hostName: window.location.hostname,
    },
    impression: { iid },
    visitor: { uid },
    geo: {},
    trafficSource,
    referrer,
    ...pubParam1,
    ...pubParam2,
  };
}
